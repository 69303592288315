<template>
  <div>
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.AKName }}</div>
      <!-- <div v-if="list.AImgUrl"
        class="essayImg">
        <img :src="list.AImgUrl"
          alt="" />
      </div> -->
      <div class="essayContent" v-html="list.Content"></div>
      <div class="video" v-if="list.Annex">
        <video style="width: 100%" :src="list.Annex" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div style="position: fixed; bottom: 16px; width: 95%">
        <van-button
          v-if="paId == 2"
          class="button"
          @click="Activity(list.AId)"
          round
          type="info"
          >心得体会</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetActivityDetail, WePBEnrolActivity } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
import { Dialog } from "vant";
import Pdfh5 from "pdfh5";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      list: {}, //活动详情
      aids: 0, //活动aid
      ActivityFrom: {}, //活动申请提交参数
      pdf: "",
      paId: "",
    };
  },
  created() {
    console.log(this.$route);
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
  },
  methods: {
    // 获取活动详情
    getRealInfo() {
      this.aids = this.$route.params.Id;
      this.paId = this.$route.params.paId;
      WxGetActivityDetail({ aId: this.aids }).then((res) => {
        this.list = res.data.data;
        // this.pdf = 'https://api.qxzhqm.cn//upload/files/2022/3/ThingJS-X应用模板制作标准.pdf'
        // console.log(this.pdf);
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
      });
    },
    // 上传心得体会
    Activity(row) {
      var path = "/detail/recActLogs/" + row;
      this.$router.push(path);
      // this.ActivityFrom.AId = this.aids;
      // this.ActivityFrom.OpenID = getOpenId();
      // WePBEnrolActivity(this.ActivityFrom).then((res) => {
      //   console.log(res);
      //   if (res.data.code == 0) {
      //     Dialog.alert({
      //       message: "提交成功!",
      //     }).then(() => {
      //       this.$router.go(-1)
      //     });

      //   } else {
      //     Dialog.alert({
      //       message: "申请失败," + res.data.msg,
      //     }).then(() => {
      //     });
      //   }
      // });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style>
.button {
  width: 95%;
  margin: 15px 2.5%;
}
</style>